<template>
  <v-row
    align="center"
    class="text-center">
    <!-- CANCEL -->
    <v-col>
      <v-btn
        icon
        @click="$emit('close')"
        color="closeDialog"
      >
        <v-icon
          x-large
        >
          mdi-close-circle-outline
        </v-icon>
      </v-btn>
    </v-col>
    <!-- SUBMIT -->
    <v-col>
      <v-btn
        @click="$emit('submit')"
        color="tertiary"
        :disabled="disabled"
        :loading="loading"
      >
        <v-icon
          large
          color="secondary"
        >
          mdi-send
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SubmissionArea',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
